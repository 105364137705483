import React, { useState, useEffect, useMemo, useRef, useContext } from 'react';
import PubNub from 'pubnub';
import { PubNubProvider, usePubNub } from 'pubnub-react';
import './_chat-box.scss';
import { BsArrowUp, BsFillBellSlashFill, BsEye } from 'react-icons/bs';
import adt_Logo from '../../logo_ ADT.svg';
import { Button } from 'react-bootstrap';
import { RiShieldStarFill } from 'react-icons/ri';
import {
    globalHandleMessage,
    timeFormatter,
    renderChatMessage,
    processMessage,
    processVideoClips,
} from '../../utils/helper';
import uniqid from 'uniqid';
import { v4 } from 'uuid';
import {
    MessageData,
    RequestPoliceSteps,
    Contact,
} from '../../interface/interface';
import api from '../../api';
import RequestPoliceMsgBox from './RequestPoliceMsgBox';
import { MessageContext } from '../../data/MessageContext';
import VideoList from './VideoList';

export const ALARM_SCORING_LOCATION = 'Person(s) at location';
export const ALARM_SCORING_DAMAGE = 'evidence of property damage';
export const ALARM_SCORING_SAFETY = 'threat to life ';
export const ALARM_SCORING_UNSURE = "I'm unsure what caused the alarm";

function ChatBox({
    activeUsers,
    cancelAlarm,
    cancelAlarmHandler,
    tokenId,
    requestPolice,
    requestPoliceHandler,
    cancelAlarmActiveHandler,
    reqPolActive,
    updateTimer,
    responseData,
    buttonBlockDisableHandler,
    handleIncidentEvent,
    setShowButtonSection,
    timerData,
}: any) {
    const matchCurrentUser = responseData.contacts.find(
        (d: Contact) => d.token === tokenId,
    );
    const currentUserDetails = {
        id: matchCurrentUser.token,
        name: matchCurrentUser.name,
    };
    const pubnub = useMemo(
        () =>
            new PubNub({
                publishKey: responseData.pn_publish_key,
                subscribeKey: responseData.pn_subscribe_key,
                uuid: currentUserDetails.id,
                autoNetworkDetection: true,
                restore: true,
            }),
        [responseData, currentUserDetails.id],
    );

    return (
        <PubNubProvider client={pubnub}>
            <Chat
                activeUsers={activeUsers}
                cancelAlarm={cancelAlarm}
                cancelAlarmHandler={cancelAlarmHandler}
                tokenId={tokenId}
                requestPolice={requestPolice}
                requestPoliceHandler={requestPoliceHandler}
                cancelAlarmActiveHandler={cancelAlarmActiveHandler}
                reqPolActive={reqPolActive}
                updateTimer={updateTimer}
                responseData={responseData}
                currentUserDetails={currentUserDetails}
                buttonBlockDisableHandler={buttonBlockDisableHandler}
                handleIncidentEvent={handleIncidentEvent}
                setShowButtonSection={setShowButtonSection}
                timerData={timerData}
            />
        </PubNubProvider>
    );
}

export const messageInitialData = [
    {
        message: '',
        publisherId: '',
        cancelAlarmTrigger: false,
        isCancelAlarmActive: false,
        requestPoliceTrigger: false,
        whoRequestForPolice: false,
        publisherName: '',
        type: '',
        action: '',
        msgTime: '',
        contacts: [],
        clips: [],
        timeExtended: false,
        isFirstClip: false,
    },
];

function Chat({
    activeUsers,
    cancelAlarm,
    cancelAlarmHandler,
    tokenId,
    requestPolice,
    requestPoliceHandler,
    cancelAlarmActiveHandler,
    reqPolActive,
    updateTimer,
    responseData,
    currentUserDetails,
    buttonBlockDisableHandler,
    handleIncidentEvent,
    setShowButtonSection,
    timerData,
}: any) {
    const pubnub = usePubNub();
    const [channels] = useState(responseData.pn_channels_to_subscribe);
    const [messages, addMessage] = useState<MessageData[]>(messageInitialData);
    const [finalFilterMessage, setFinalFilterMessage] = useState<MessageData[]>(
        [],
    );
    const [message, setMessage] = useState<string>('');
    const followSteps: RequestPoliceSteps = {
        firstStepRP: true,
        secondStepRP: false,
        thirdStepRP: false,
        avs01: false,
    };
    const [requestPoliceSteps, setRequestPoliceSteps] =
        useState<RequestPoliceSteps>(followSteps);
    const [cancelButtonLoading, setCancelButtonLoading] =
        useState<boolean>(false);

    const { updateMessageData } = useContext(MessageContext)!;

    const storedToken = localStorage.getItem('api_Token');
    const tenantId = localStorage.getItem('tenant_id');
    const currentDate = new Date().toISOString();
    const [selectedQuestions, setSelectedQuestions] = useState<string[]>([]);
    const [etaValue, setEtaValue] = useState('');

    const timeTokenAdjustementSuffix = '0000';

    const sendMessage = async (messageString: string) => {
        setMessage('');
        if (messageString) {
            const message = {
                type: 'EVENT_MESSAGE',
                payload: {
                    event_type: 'EVENT_MESSAGE',
                    sender: {
                        name: currentUserDetails.name,
                        token: currentUserDetails.id,
                    },
                    body: messageString,
                },
            };
            pubnub.publish({ channel: channels[0], message });
        }
    };

    useEffect(() => {
        if (cancelAlarm) {
            addMessage((e: any) => [
                {
                    cancelAlarmTrigger: true,
                    publisherId: currentUserDetails.id,
                    msgTime: `${String(
                        Number.MAX_SAFE_INTEGER,
                    )}${timeTokenAdjustementSuffix}`,
                },
                ...e,
            ]);
        }
        if (requestPolice) {
            addMessage((e: any) => [
                {
                    requestPoliceTrigger: true,
                    publisherId: currentUserDetails.id,
                    msgTime: `${String(
                        Number.MAX_SAFE_INTEGER,
                    )}${timeTokenAdjustementSuffix}`,
                },
                ...e,
            ]);
        }
        // eslint-disable-next-line
    }, [cancelAlarm, requestPolice]);

    useEffect(() => {
        const listenerParams = {
            message: (message: any) =>
                globalHandleMessage(
                    message,
                    addMessage,
                    activeUsers,
                    updateMessageData,
                    cancelAlarmActiveHandler,
                    reqPolActive,
                    requestPoliceDefault,
                    handleIncidentEvent,
                ),
        };
        pubnub.addListener(listenerParams);
        pubnub.subscribe({ channels, withPresence: true });

        // eslint-disable-next-line
    }, [pubnub, channels]);

    useEffect(() => {
        const sortedArray = [...messages].sort(
            (a: MessageData, b: MessageData) => {
                // Fallback to Unix epoch start if undefined
                const timeA = BigInt(a.msgTime ?? '0');
                const timeB = BigInt(b.msgTime ?? '0');
                if (timeA > timeB) return -1; // For descending order
                if (timeA < timeB) return 1;
                return 0;
            },
        );
        setFinalFilterMessage(sortedArray);
    }, [messages]);

    // Helper function to fetch messages for a given channel
    const fetchChannelMessages = async (
        channel: string,
        startTimeToken: number,
        totalFetched: number,
        pubnub: any,
    ) => {
        try {
            const messagesToFetch = Math.min(100 - totalFetched, 100);
            const response = await pubnub.fetchMessages({
                channels: [channel],
                count: messagesToFetch,
                stringifiedTimeToken: true,
                includeMeta: true,
                includeMessageActions: true,
                start: startTimeToken,
            });
            return response;
        } catch (error) {
            throw error;
        }
    };

    const fetchHistoryRecursive = async (
        channel: string,
        startTimeToken: number,
        totalFetched: number,
        pubnub: any,
    ) => {
        const response = await fetchChannelMessages(
            channel,
            startTimeToken,
            totalFetched,
            pubnub,
        );

        if (!response || !Object.keys(response.channels).length) {
            return;
        }

        const newMessages = processMessages(response.channels[channel]);
        if (!newMessages) return;

        const filteredArray = filterAndSortMessages(newMessages);
        if (filteredArray.length) {
            addMessage((oldData) => [...oldData, ...filteredArray]);
        }

        const fetchedMessageCount = newMessages.length;
        if (totalFetched + fetchedMessageCount < 100) {
            const lastMessageTimeToken = getLastMessageTimeToken(
                response.channels[channel],
            );
            if (lastMessageTimeToken) {
                await fetchHistoryRecursive(
                    channel,
                    lastMessageTimeToken,
                    totalFetched + fetchedMessageCount + 1,
                    pubnub,
                );
            }
        }
    };

    const processMessages = (messages: any) => {
        return messages?.map((m: any) => {
            if (m.channel.includes('clip')) {
                return processVideoClips(m, addMessage);
            } else if (m.message?.type && m.message?.payload?.sender) {
                return processMessage(m, activeUsers);
            } else if (!m.message?.payload?.sender && m.message?.type) {
                return {
                    type: m.message.type || '',
                    contacts: m.message.payload.contacts,
                };
            }
            return messageInitialData;
        });
    };

    const filterAndSortMessages = (messages: MessageData[]): MessageData[] => {
        const filteredArray = messages.filter(
            (item: any) => item !== undefined,
        );
        return filteredArray;
    };

    const getLastMessageTimeToken = (
        channelData: any[],
    ): number | undefined => {
        return channelData[0]?.timetoken;
    };

    // Main useEffect function
    const fetchAllHistories = async () => {
        await Promise.all(
            channels.map(async (channel: string) => {
                await fetchHistoryRecursive(channel, 0, 0, pubnub);
            }),
        );
    };

    useEffect(() => {
        fetchAllHistories();
        // eslint-disable-next-line
    }, [pubnub, channels]);

    const removeCancelAlarm = () => {
        cancelAlarmHandler();
        addMessage(messages.filter((item) => item.cancelAlarmTrigger !== true));
    };

    const removeRequestPolice = () => {
        requestPoliceHandler(false);
        addMessage(
            messages.filter((item) => item.requestPoliceTrigger !== true),
        );
        setRequestPoliceSteps(followSteps);
    };

    const requestPoliceDefault = () => {
        setRequestPoliceSteps(followSteps);
    };

    const forCancelAlarmActive = () => {
        setShowButtonSection(false);
        setCancelButtonLoading(true);
        if (storedToken && currentDate) {
            api.post(
                `${tenantId}/cancel`,
                {
                    timestamp: currentDate,
                },
                {
                    headers: {
                        Authorization: `Bearer ${storedToken}`,
                        'Content-Type': 'application/json',
                        correlationId: v4(),
                    },
                },
            )
                .then(() => {
                    cancelAlarmActiveHandler();
                    setCancelButtonLoading(false);
                })
                .catch((err) => {
                    if (err.response.status === 400) {
                        removeCancelAlarm();
                    }
                });

            setMessage('');
        }
    };

    // Function to set etaValue
    const setEta = (eta: string) => {
        setEtaValue(eta); // Update the state

        //if avs01 not set we just want to publish the message now
        if (!avs01) {
            whoRequestPoliceHandler(eta, selectedQuestions);
        }
        forChangesSteps('avs01');
    };

    const forChangesSteps = (params: string) => {
        if (params === 'second') {
            setShowButtonSection(false);
            setRequestPoliceSteps((e: any) => {
                return {
                    ...e,
                    firstStepRP: false,
                    secondStepRP: true,
                };
            });
        } else if (params === 'third') {
            setRequestPoliceSteps((e: any) => {
                return {
                    ...e,
                    firstStepRP: false,
                    secondStepRP: false,
                    thirdStepRP: true,
                    avs01: false,
                };
            });
        } else if (params === 'allStp') {
            setRequestPoliceSteps((e: any) => {
                return {
                    ...e,
                    firstStepRP: true,
                    secondStepRP: false,
                    thirdStepRP: false,
                    avs01: false,
                };
            });
        } else if (params === 'avs01') {
            setRequestPoliceSteps((e: any) => {
                return {
                    ...e,
                    firstStepRP: false,
                    secondStepRP: false,
                    thirdStepRP: false,
                    avs01: true,
                };
            });
        } else {
            setRequestPoliceSteps(followSteps);
        }
    };

    const handleSelectionChange = (
        e: React.ChangeEvent<HTMLInputElement>,
    ): void => {
        const { value, checked } = e.target;

        if (value === ALARM_SCORING_UNSURE && checked) {
            setSelectedQuestions([ALARM_SCORING_UNSURE]);
        } else if (selectedQuestions.includes(ALARM_SCORING_UNSURE)) {
            setSelectedQuestions([value]);
        } else if (checked) {
            setSelectedQuestions((prevState) => [...prevState, value]);
        } else {
            setSelectedQuestions((prevState) =>
                prevState.filter((item) => item !== value),
            );
        }
    };

    const publishRequestPoliceMessage = (
        end_user_eta: string,
        end_user_people_location: boolean,
        end_user_property_damage: boolean,
        end_user_concern_for_safety: boolean,
        end_user_unsure_of_cause: boolean,
    ) => {
        if (storedToken && currentDate) {
            buttonBlockDisableHandler(true);
            api.post(
                `${tenantId}/police`,
                {
                    end_user_share_data: false,
                    end_user_eta: end_user_eta,
                    ...(avs01 && {
                        end_user_people_location: end_user_people_location,
                        end_user_property_damage: end_user_property_damage,
                        end_user_concern_for_safety:
                            end_user_concern_for_safety,
                        end_user_unsure_of_cause: end_user_unsure_of_cause,
                    }),
                    timestamp: currentDate,
                },
                {
                    headers: {
                        Authorization: `Bearer ${storedToken}`,
                        'Content-Type': 'application/json',
                        correlationId: v4(),
                    },
                },
            )
                .then(() => {
                    reqPolActive();
                    buttonBlockDisableHandler(false);
                })
                .catch((err) => {
                    if (err.response.status === 400) {
                        removeRequestPolice();
                        buttonBlockDisableHandler(false);
                    }
                });

            setMessage('');
        }
    };

    const whoRequestPoliceHandler = (
        getMins: string,
        selectedQuestions: string[],
    ) => {
        publishRequestPoliceMessage(
            getMins,
            selectedQuestions.includes(ALARM_SCORING_LOCATION),
            selectedQuestions.includes(ALARM_SCORING_DAMAGE),
            selectedQuestions.includes(ALARM_SCORING_SAFETY),
            selectedQuestions.includes(ALARM_SCORING_UNSURE),
        );
        setRequestPoliceSteps({ ...followSteps, firstStepRP: false });
        requestPoliceHandler(false);
    };

    const avs01 = localStorage.getItem('avs01') === 'true';

    const inputRef = useRef<HTMLInputElement>(null);

    const handleFocus = () => {
        const input = inputRef.current;
        if (input) {
            // Move the cursor to the end
            setTimeout(() => {
                input.selectionStart = input.selectionEnd = input.value.length;
                // Manually set scroll position
                input.scrollLeft = input.scrollWidth;
            }, 100);
        }
    };

    return (
        <>
            <div className="chat-input-area" data-testid="chat-input-area-top">
                <input
                    ref={inputRef}
                    type="text"
                    placeholder="Type your message"
                    onFocus={handleFocus}
                    value={message}
                    data-testid="chat-input-message-box"
                    onKeyDown={(e) => {
                        if (e.key !== 'Enter') return;
                        sendMessage(message);
                    }}
                    onChange={(e) => setMessage(e.target.value)}
                />
                <button
                    onClick={(e) => {
                        e.preventDefault();
                        sendMessage(message);
                    }}
                    data-testid="bs-arrow-icon"
                >
                    <BsArrowUp />
                </button>
            </div>
            <div
                className="message-container"
                data-testid="main-message-container"
            >
                {finalFilterMessage.map((message) => {
                    const renderJoinUser = () => (
                        <div className="who-join-in-chanel" key={uniqid()}>
                            <p>
                                {' '}
                                + <b>{message.publisherName}</b> has joined.
                            </p>
                        </div>
                    );

                    const renderAdtInformationMessage = () => (
                        <>
                            {message.peopleAtTheLocation && (
                                <div
                                    className="who-join-in-chanel"
                                    key={uniqid()}
                                >
                                    <p>
                                        {' '}
                                        <b>{message.publisherName}</b> selected
                                        "Person(s) at location <b>or</b>{' '}
                                        evidence of property damage."
                                    </p>
                                </div>
                            )}

                            {message.evidenceOfPropertyDamage && (
                                <div
                                    className="who-join-in-chanel"
                                    key={uniqid()}
                                >
                                    <p>
                                        {' '}
                                        <b>{message.publisherName}</b> selected
                                        "Person(s) at location <b>and</b>{' '}
                                        evidence of property damage."
                                    </p>
                                </div>
                            )}

                            {message.concernForSomeonePersonalSafety && (
                                <div
                                    className="who-join-in-chanel"
                                    key={uniqid()}
                                >
                                    <p>
                                        {' '}
                                        <b>{message.publisherName}</b> selected
                                        "Person(s) at location <b>and</b> threat
                                        to life."
                                    </p>
                                </div>
                            )}

                            {message.unsureOfWhatCausedTheAlarm && (
                                <div
                                    className="who-join-in-chanel"
                                    key={uniqid()}
                                >
                                    <p>
                                        {' '}
                                        <b>{message.publisherName}</b> selected
                                        "I’m unsure what caused the alarm."
                                    </p>
                                </div>
                            )}

                            {message.endUserEta === 'Onsite' && (
                                <div
                                    className="who-join-in-chanel"
                                    key={uniqid()}
                                >
                                    <p>
                                        <b>{message.publisherName}</b> is
                                        onsite.
                                    </p>
                                </div>
                            )}

                            {message.endUserEta === '< 15' && (
                                <div
                                    className="who-join-in-chanel"
                                    key={uniqid()}
                                >
                                    <p>
                                        <b>{message.publisherName}</b> is en
                                        route with an ETA of less than 15 mins.
                                    </p>
                                </div>
                            )}

                            {message.endUserEta === '< 30' && (
                                <div
                                    className="who-join-in-chanel"
                                    key={uniqid()}
                                >
                                    <p>
                                        <b>{message.publisherName}</b> is en
                                        route with an ETA of less than 30 mins.
                                    </p>
                                </div>
                            )}

                            {message.endUserEta === '30 plus' && (
                                <div
                                    className="who-join-in-chanel"
                                    key={uniqid()}
                                >
                                    <p>
                                        <b>{message.publisherName}</b> is en
                                        route with an ETA of more than 30 mins.
                                    </p>
                                </div>
                            )}

                            <RequestPoliceMsgBox
                                publisherName={message.publisherName}
                                msgTime={message.msgTime}
                            />
                        </>
                    );

                    const renderRegularMessage = () => (
                        <div key={uniqid()}>
                            {message.message &&
                                message.type === 'EVENT_MESSAGE' && (
                                    <div>
                                        <div
                                            className={`d-flex align-items-center mt-4 position-relative ${
                                                currentUserDetails.id ===
                                                message.publisherId
                                                    ? 'flex-row-reverse'
                                                    : 'outside-message'
                                            }`}
                                        >
                                            <div className="user-avatar-outer">
                                                <div className="user-avatar">
                                                    {message.publisherName?.charAt(
                                                        0,
                                                    )}
                                                </div>
                                            </div>
                                            <div className="position-relative">
                                                <div className="msg-time">{`${
                                                    currentUserDetails.id ===
                                                    message.publisherId
                                                        ? 'You'
                                                        : message.publisherName
                                                } at ${timeFormatter(
                                                    message.msgTime,
                                                )}`}</div>
                                                <div className="current-user-message">
                                                    {message.message}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                            {message.message &&
                                message.type === 'TIME_EXPIRED' &&
                                !!responseData?.zero_delay_customer !==
                                    true && (
                                    <div
                                        className="default-block-view"
                                        key={uniqid()}
                                    >
                                        <div
                                            className="d-flex align-items-center mt-4 position-relative outside-message"
                                            key={uniqid()}
                                        >
                                            <div className="user-avatar-logo">
                                                <img
                                                    src={adt_Logo}
                                                    alt="adt-logo"
                                                />
                                            </div>
                                            <div className="position-relative">
                                                <div className="msg-time">
                                                    {`ADT Monitoring Alert at ${timeFormatter(
                                                        message.msgTime,
                                                    )}`}
                                                </div>
                                                <p className="d-flex current-user-message timeout-message">
                                                    <b>
                                                        This Alarm has been sent
                                                        to ADT Agents.
                                                    </b>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                        </div>
                    );

                    const renderCancelAlarmTrigger = () =>
                        message.cancelAlarmTrigger &&
                        cancelAlarm && (
                            <div
                                className="d-flex align-items-center position-relative outside-message mb-4 mt-2"
                                key={uniqid()}
                            >
                                <div className="user-avatar-logo">
                                    <img src={adt_Logo} alt="adt-logo" />
                                </div>
                                <div className="position-relative">
                                    <div className="cancel-alarm-message">
                                        <div className="d-flex">
                                            <div className="alarm-icon">
                                                <BsFillBellSlashFill />
                                            </div>
                                            <p>
                                                Are you sure you want to cancel
                                                this alarm?
                                            </p>
                                        </div>
                                        <div className="d-flex justify-content-between mb-2 chat-box--msg--block">
                                            <Button
                                                className="msg-inside-button"
                                                onClick={removeCancelAlarm}
                                                data-testid="cancel-alarm-button-no"
                                                disabled={cancelButtonLoading}
                                                variant=""
                                            >
                                                No
                                            </Button>
                                            {message.publisherId && (
                                                <Button
                                                    className="msg-inside-button"
                                                    onClick={
                                                        forCancelAlarmActive
                                                    }
                                                    data-testid="cancel-alarm-button-yes"
                                                    disabled={
                                                        cancelButtonLoading
                                                    }
                                                    variant=""
                                                >
                                                    {cancelButtonLoading && (
                                                        <span className="spinner-grow spinner-grow-sm text-light"></span>
                                                    )}
                                                    Yes
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                    <div className="msg-indicator">
                                        <div className="d-flex">
                                            <BsEye />
                                            <p>Only visible to you</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );

                    const renderIsCancelAlarmActive = () =>
                        !message.cancelAlarmTrigger && (
                            <div
                                className="d-flex align-items-center position-relative outside-message mb-2 mt-2"
                                key={uniqid()}
                            >
                                <div className="user-avatar-logo">
                                    <img src={adt_Logo} alt="adt-logo" />
                                </div>
                                <div className="position-relative">
                                    <div className="cancel-alarm-message alarm-info">
                                        <div className="d-flex">
                                            <div className="alarm-icon">
                                                <BsFillBellSlashFill />
                                            </div>
                                            <p>{`Alarm canceled by ${
                                                message.publisherName
                                            } at ${timeFormatter(
                                                message.msgTime,
                                            )}`}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );

                    const renderRequestPoliceTrigger = () =>
                        requestPolice &&
                        requestPoliceHandler && (
                            <div
                                className="d-flex align-items-center position-relative outside-message mb-4 mt-2"
                                key={uniqid()}
                            >
                                <div className="user-avatar-logo">
                                    <img src={adt_Logo} alt="adt-logo" />
                                </div>
                                <div className="position-relative">
                                    {requestPoliceSteps.firstStepRP &&
                                        !requestPoliceSteps.secondStepRP && (
                                            <div className="request-police-message">
                                                <div className="d-flex">
                                                    <div className="alarm-icon">
                                                        <RiShieldStarFill />
                                                    </div>
                                                    <p>
                                                        Are you sure you want to{' '}
                                                        <b>Request Police?</b>
                                                    </p>
                                                </div>
                                                <div className="d-flex justify-content-between mb-2 chat-box--msg--block">
                                                    <Button
                                                        className="msg-inside-button"
                                                        data-testid="request-police-button-no"
                                                        onClick={
                                                            removeRequestPolice
                                                        }
                                                    >
                                                        No
                                                    </Button>
                                                    <Button
                                                        className="msg-inside-button"
                                                        data-testid="request-police-button-yes"
                                                        onClick={() =>
                                                            forChangesSteps(
                                                                'second',
                                                            )
                                                        }
                                                    >
                                                        Yes
                                                    </Button>
                                                </div>
                                            </div>
                                        )}
                                    {!requestPoliceSteps.firstStepRP &&
                                        requestPoliceSteps.secondStepRP && (
                                            <div className="request-police-message bg-site-blue">
                                                <div className="d-flex">
                                                    <p>
                                                        If responding, what is
                                                        your ETA?
                                                    </p>
                                                </div>
                                                <div className="d-flex justify-content-between mb-2 chat-box--msg--block">
                                                    <Button
                                                        className="msg-inside-button"
                                                        onClick={() =>
                                                            setEta('Onsite')
                                                        }
                                                    >
                                                        I'm Here
                                                    </Button>
                                                    <Button
                                                        className="msg-inside-button chat-box--msg--block"
                                                        onClick={() =>
                                                            setEta('< 15')
                                                        }
                                                    >
                                                        {'< 15 Mins'}
                                                    </Button>
                                                </div>
                                                <div className="d-flex justify-content-between mb-2 chat-box--msg--block">
                                                    <Button
                                                        className="msg-inside-button"
                                                        onClick={() =>
                                                            setEta('< 30')
                                                        }
                                                    >
                                                        {'< 30 Mins'}
                                                    </Button>
                                                    <Button
                                                        className="msg-inside-button chat-box--msg--block"
                                                        onClick={() =>
                                                            setEta('30 plus')
                                                        }
                                                    >
                                                        {'30+ Mins'}
                                                    </Button>
                                                </div>
                                                <div className="d-flex justify-content-between mb-2 chat-box--msg--block">
                                                    <Button
                                                        className="msg-inside-button chat-box-full-button"
                                                        onClick={() =>
                                                            setEta(
                                                                'Not Responding',
                                                            )
                                                        }
                                                    >
                                                        I'm Not Responding
                                                    </Button>
                                                </div>
                                            </div>
                                        )}
                                    {!requestPoliceSteps.firstStepRP &&
                                        !requestPoliceSteps.secondStepRP &&
                                        requestPoliceSteps.avs01 && (
                                            <div
                                                className={
                                                    'request-police-message bg-site-blue'
                                                }
                                            >
                                                <div
                                                    className={
                                                        'confirmation-header'
                                                    }
                                                >
                                                    Do you have confirmation of:
                                                    (Check all that apply)
                                                </div>
                                                <div
                                                    className={
                                                        'alarm-scoring-checkbox-group'
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            'alarm-scoring-check'
                                                        }
                                                    >
                                                        <label
                                                            className={
                                                                'labelStyle'
                                                            }
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                onChange={
                                                                    handleSelectionChange
                                                                }
                                                                value={
                                                                    ALARM_SCORING_LOCATION
                                                                }
                                                                checked={selectedQuestions.includes(
                                                                    ALARM_SCORING_LOCATION,
                                                                )}
                                                                className={
                                                                    'checkbox-style'
                                                                }
                                                                data-testid="alarm-score-location"
                                                            />
                                                            <span className="alarm-scoring-checkbox-text">
                                                                {
                                                                    ALARM_SCORING_LOCATION
                                                                }
                                                                <strong>
                                                                    {' '}
                                                                    or{' '}
                                                                </strong>
                                                                {
                                                                    ALARM_SCORING_DAMAGE
                                                                }
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div
                                                        className={
                                                            'alarm-scoring-check'
                                                        }
                                                    >
                                                        <label
                                                            className={
                                                                'labelStyle'
                                                            }
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                onChange={
                                                                    handleSelectionChange
                                                                }
                                                                value={
                                                                    ALARM_SCORING_DAMAGE
                                                                }
                                                                checked={selectedQuestions.includes(
                                                                    ALARM_SCORING_DAMAGE,
                                                                )}
                                                                className={
                                                                    'checkbox-style'
                                                                }
                                                                data-testid="alarm-score-damage"
                                                            />
                                                            <span className="alarm-scoring-checkbox-text">
                                                                {
                                                                    ALARM_SCORING_LOCATION
                                                                }
                                                                <strong>
                                                                    {' '}
                                                                    and{' '}
                                                                </strong>
                                                                {
                                                                    ALARM_SCORING_DAMAGE
                                                                }
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div
                                                        className={
                                                            'alarm-scoring-check'
                                                        }
                                                    >
                                                        <label
                                                            className={
                                                                'labelStyle'
                                                            }
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                onChange={
                                                                    handleSelectionChange
                                                                }
                                                                value={
                                                                    ALARM_SCORING_SAFETY
                                                                }
                                                                checked={selectedQuestions.includes(
                                                                    ALARM_SCORING_SAFETY,
                                                                )}
                                                                className={
                                                                    'checkbox-style'
                                                                }
                                                                data-testid="alarm-score-safety"
                                                            />
                                                            <span className="alarm-scoring-checkbox-text">
                                                                {
                                                                    ALARM_SCORING_LOCATION
                                                                }
                                                                <strong>
                                                                    {' '}
                                                                    and{' '}
                                                                </strong>
                                                                {
                                                                    ALARM_SCORING_SAFETY
                                                                }
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div
                                                        className={
                                                            'alarm-scoring-check'
                                                        }
                                                    >
                                                        <label
                                                            className={
                                                                'labelStyle'
                                                            }
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                onChange={
                                                                    handleSelectionChange
                                                                }
                                                                value={
                                                                    ALARM_SCORING_UNSURE
                                                                }
                                                                checked={selectedQuestions.includes(
                                                                    ALARM_SCORING_UNSURE,
                                                                )}
                                                                className={
                                                                    'checkbox-style'
                                                                }
                                                                data-testid="alarm-score-unsure"
                                                            />
                                                            <span className="alarm-scoring-checkbox-text">
                                                                {
                                                                    ALARM_SCORING_UNSURE
                                                                }
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-between mb-2 chat-box--msg--block">
                                                    <button
                                                        className="msg-inside-button submit-style"
                                                        onClick={() =>
                                                            whoRequestPoliceHandler(
                                                                etaValue,
                                                                selectedQuestions,
                                                            )
                                                        }
                                                        color="light"
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    <div className="msg-indicator">
                                        <div className="d-flex">
                                            <BsEye />
                                            <p>Only visible to you</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );

                    const renderTimeAddMsg = () => (
                        <div className="who-join-in-chanel" key={uniqid()}>
                            <p>
                                + {(timerData.timeToExtend / 1000)} Seconds added by{' '}
                                <b>{message.publisherName}</b>.
                            </p>
                        </div>
                    );

                    const videoClipsRender = () => {
                        return (
                            <div>
                                <div
                                    className="default-block-view"
                                    key={uniqid()}
                                >
                                    <div className="d-flex align-items-center mt-4 position-relative outside-message">
                                        <div className="position-relative">
                                            <div className="msg-time clip-area">
                                                {timeFormatter(message.msgTime)}
                                            </div>
                                            <VideoList
                                                videoClips={message.clips}
                                                key={uniqid()}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {message.isFirstClip && (
                                    <div
                                        className="d-flex align-items-center position-relative mb-4"
                                        key={uniqid()}
                                    >
                                        <div className="user-avatar-logo">
                                            <img
                                                src={adt_Logo}
                                                alt="adt-logo"
                                            />
                                        </div>
                                        <div className="position-relative">
                                            <div className="default-message">
                                                <div className="d-flex">
                                                    <p>
                                                        Video has been received.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        );
                    };

                    return renderChatMessage(message, tokenId, {
                        renderAdtInformationMessage,
                        renderRegularMessage,
                        renderCancelAlarmTrigger,
                        renderIsCancelAlarmActive,
                        renderRequestPoliceTrigger,
                        renderJoinUser,
                        renderTimeAddMsg,
                        videoClipsRender,
                    });
                })}

                <div className="default-block-view" key={uniqid()}>
                    <div
                        className="default-message margin-left-50"
                        key={uniqid()}
                    >
                        <div className="d-flex">
                            <p>
                                An alarm has been triggered
                                {responseData.address && (
                                    <span>
                                        {' '}
                                        at
                                        <b>{` ${responseData.address.address1} `}</b>
                                    </span>
                                )}
                                {responseData.incident && (
                                    <span>
                                        from
                                        <b>{` ${responseData.incident.description}`}</b>
                                        {'.'}
                                    </span>
                                )}
                            </p>
                        </div>
                    </div>
                    <div
                        className="d-flex align-items-center mt-2 position-relative"
                        key={uniqid()}
                    >
                        <div className="user-avatar-logo">
                            <img src={adt_Logo} alt="adt-logo" />
                        </div>
                        <div className="position-relative">
                            <div className="default-message">
                                <div className="d-flex">
                                    <p>
                                        You can <b>cancel</b> the alarm or{' '}
                                        <b>request police</b> with the buttons
                                        above.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="who-join-in-chanel" key={uniqid()}>
                        <p>
                            Today{' '}
                            <b>
                                {new Date(
                                    responseData.created_at,
                                ).toLocaleString('en-US', {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hour12: true,
                                })}
                            </b>
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ChatBox;
